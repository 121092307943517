import React from "react";
import { useEffect, useState} from "react";
import axios from "axios";
import logoForge from "./logo_FORGETRONIC_rgb.png";
import Card from "./Card";

function DynamicPage({ page }) {
  const [fileContents, setFileContents] = useState([]);
  const [error, setError] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  
  useEffect(() => {
    // Funzione per caricare i dati
    let isMounted = true;
    const fetchData = async () => {
      try {
        if (!isMounted) return;
        if (firstLoad) {
          setError(null); // Reset dell'errore solo all'inizio
        }
        const responseFiles = await axios.get(`/${page.path}/CM.json`);
        const files = responseFiles.data;
        //console.log(files)
        const fileRequests = files.map((file) =>
          axios
            .get(`/${page.path}/${file.commessa}.json`)
            .then((response) => {
              return Array.isArray(response.data)? response.data: [response.data];
            })
            .catch((error) => {
              console.error(`Errore nel file ${file.commessa}.json:`, error);
              return [];
            })
        );
        const fileResponses = await Promise.all(fileRequests);
        const lista = fileResponses.flat();

        if (isMounted) {
          setFileContents(lista);
          if (firstLoad) setError(null); // Rimuovi errore solo se il primo caricamento riesce
          setFirstLoad(false);
        }
      } catch (error) {
        console.error("Errore durante il caricamento dei dati:", error);
        if (isMounted && firstLoad) {
          setError("Impossibile caricare i dati. Riprova più tardi.");
        }
      }       
    };

    fetchData();
    // Imposta un intervallo per caricare i dati ogni 20 secondi
    const intervalId = setInterval(fetchData, 20000);
    // Pulisce l'intervallo quando il componente viene smontato
    return () => clearInterval(intervalId);
  }, [page]);

  if (error) {
    return <div style={{ color: "red" }}>{error}</div>;
  }

  return (
    <div className="max-w-md mx-auto bg-gray-50 rounded-xl shadow-md overflow-hidden md:max-w-2xl p-3">
      <div className=" bg-white rounded-2xl p-10 flex-auto ">
        <img src={logoForge} className="my-2"></img>
        <p className="text-gray-500 font-thin">App di monitoraggio</p>
      </div>
      <div className="my-5">
        {fileContents.length > 0 ? (
          <ul style={{width: "100%"}}>
            {fileContents.map((item) => (
              <Card key = {item.machine_name}
                id = {item.machine_name}
                externalData = {item}                
              />
            ))}
          </ul>
        ) : (
          <p>Caricamento...</p>
        )}
      </div>
    </div>
  );
}

export default DynamicPage;
