import { useEffect, useState, useRef } from "react";

function Card({ externalData, id }) {
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [isAlarm, setIsAlarm]= useState(false);
  const [infos, setInfos] = useState([]);
  const timeoutRef = useRef(null);
  
  useEffect(() => {
    if (externalData) {
      setInfos(externalData.info.tiles)
      if (CheckError(externalData.info.messages)){
        setIsAlarm(true)
      }
      else{
        setIsAlarm(false)
      }
      if (ControlTime(externalData.started_at)) {
        //console.log(new Date().toISOString())
        setIsDisconnected(true);
      } 
      else{
        setIsDisconnected(false);
      }      
       timeoutRef.current = setTimeout(() => {
       }, 3000); 
      return () => clearTimeout(timeoutRef);
    }
  }, [externalData, id]);

  function CheckError(messageList) {
    try {
      return messageList.some((element) => {
        if (element.type === "error") {
          return true; 
        }
        return false;
      });
    } catch (error) {
      console.log("[CheckError] Errore verifica lista messaggi", error)
    }

  }

  function ControlTime(receivedTime) {
    try {
      const now = Date.now(); 
      const receivedTimestamp = new Date(receivedTime).getTime(); 
      return Math.abs(now - receivedTimestamp) > 60000;
    } catch (error) {
      console.log("[ControlTime] Errore nel controllo tempo", error)
    }
  };

  function CardColor(stato) {
    try {
      if (stato === "on") {
        return " bg-green-100 rounded-2xl my-5 p-5 flex-wrap shadow";
      } else if (stato === "tooling") {
        return "bg-yellow-100 rounded-2xl my-5 p-5 flex-wrap shadow";
      } else if (stato === "off") {
        return "bg-orange-100 rounded-2xl my-5 p-5 flex-wrap shadow";
      } else if (stato === "Disattivo") {
        return "bg-gray-100 rounded-2xl my-5 p-5 flex-wrap shadow";
      }
    } catch (error) {
      console.log("[CardColor] Errore nell'analisi dello stato", error)
    }
  }


  return (
    <div
      className={isDisconnected ? CardColor("Disattivo") : CardColor(externalData.state)}>
      <p className="font-semibold text-xl">{externalData.machine_name}</p>
      <section style={{display: "flex", flexDirection: "row", alignItems:"center"}}>
        <p >State:{" "}</p>
        <p style={{fontSize: "20px", marginLeft: "5px"}}>{isDisconnected ? "⚠️ DISCONNECTED" : externalData.state.toUpperCase()}</p>
      </section>      
      <p>Code: {externalData.product_code}</p>
      <p style={{ fontSize: "18px", color: "red" }}>  {isAlarm ? "🔔 ALARM" : ""} </p>
      <div>
        <h1>Info:</h1>
        <div style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between"}}>
          {infos.map((item, index) => (
            <section
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                height: "55%",
                width: "45%",
                margin: "4px",
                padding: "15px",
                border: "1px solid #6b7280",
                borderRadius:"15px",
                boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)", 
                color: "black",
                fontFamily :"Arial, sans-serif", 
                textAlign: "left"
              }}
              key={index}>
              <section style={{ fontSize: "14px", color:"#666" }}>{item.label} :{" "}</section>
              <section style={{ fontSize: "18px", color:"#333" }}>{item.value}</section>
            </section>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Card;
