import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DynamicPage from './components/DynamicPage';
import axios from 'axios';

function App() {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPages = async () => {
      setLoading(true); // Imposta loading a true all'inizio del caricamento
      try {
        const response = await axios.get('/pages.json');
        //console.log(response.data)
        setPages(response.data || []);
        setLoading(false); // Imposta loading a false dopo aver caricato i dati
      } catch (error) {
        console.error('Errore durante il caricamento delle pagine:', error);
        setLoading(false); // Anche in caso di errore, imposta loading a false
      }
    };

    fetchPages();
  }, []);

  if (loading) {
    return <div>Caricamento...</div>; // Mostra lo stato di caricamento
  }


  return (
    <Router>
    <Routes>
        {pages.map(page => (
          <Route
            key={page.path}
            path={`/${page.path}`}
            element={<DynamicPage page={page} />}
          />
        ))}
        <Route path="/" element={
          <div>
            <h1>Benvenuto sul sito</h1>
            <p>Seleziona una pagina dal menu.</p>
          </div>
        } />
        <Route path="*" element={<h1>CDI - Pagina non trovata</h1>} />
      </Routes>
    </Router>

  );
}

export default App;